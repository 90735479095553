<template>
  <VRow
    row
    wrap
    fill-height
  >
    <Calendars
      ref="calendars"
      class="flex xs12 md3"
      :current-calendar="calendar"
      :drawer="calendarsDrawer"
      calendar-mode="multiple"
      @savedCalendar="onCalendarSaved"
      @calendarDeleted="onCalendarDeleted"
      @calendars="setCalendars"
      @updateActiveCalendars="setActiveCalendars"
      @toggleDrawer="onToggleCalendarsDrawer"
    />
    <VCol
      xs12
      md9
      fill-height
    >
      <VCard class="fill-height calendar-container">
        <VCardText class="fill-height py-2 px-2">
          <Calendar
            can-create
            can-create-event
            :show-date="showDate"
            :events="allEvents"
            @toggleDrawer="onToggleCalendarsDrawer"
            @input="setShowDate"
            @savedCalendar="onCalendarSaved"
          />
        </VCardText>
      </VCard>
    </VCol>
    <FormDialog
      button-text="RSVP"
      max-width="500px"
      model-type="RSVP"
      :is-dialog-open="dialogs.rsvp"
      :title="(currentCalendarEntry || {}).title"
      @toggle:form:dialog="onToggleRsvpDialog"
    >
      <RSVPToEvent
        ref="saveForm"
        mode="dialog"
        :calendar-entry="currentCalendarEntry"
      />
    </FormDialog>
  </VRow>
</template>

<script>
import { mapActions } from "vuex";
import Calendars from "./Calendars";
import UserRSVPList from "@/components/Elements/Data/UserRSVPList";
import HasCalendarEntries from "@/mixins/HasCalendarEntries";
import RSVPToEvent from "./RSVPToEvent";
export default {
  name: "CalendarIndex",
  components: {
    Calendars,
    RSVPToEvent
  },
  mixins: [HasCalendarEntries],
  props: {
    calendarId: {
      type: Number,
      default: null
    },
    calendarsOnly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      drawer: false
    };
  },
  created() {
    this.$log.debug("Calendar Index: Component mounted.");
    if (
      this.$route.name === "calendar.event" &&
      this.$route.params.calendarId &&
      this.$route.params.calendarEntryId
    ) {
      this.$log.debug("Calendar Index: Request to view event!");
      this.getCalendarEntry({
        calendarId: this.$route.params.calendarId,
        id: this.$route.params.calendarEntryId
      }).then(() => {
        this.onToggleRsvpDialog();
      });
    } else if (
      ["calendar.status", "calendar.rsvp"].indexOf(this.$route.name) &&
      this.$route.params.calendarId &&
      this.$route.params.calendarEntryId
    ) {
      this.$log.debug("Calendar Index: There's an RSVP request!");
      this.getCalendarEntry({
        calendarId: this.$route.params.calendarId,
        id: this.$route.params.calendarEntryId
      }).then(() => {
        this.onToggleRsvpDialog();
      });
    } else if (this.calendarId == "public") {
      /**
       * Get last calendar from session storage
       */
      let calendar = window.localStorage.getItem("lastCalendar");
      if (calendar) {
        this.$log.debug("Calendar Index: Found last calendar", calendar);
        calendar = JSON.parse(calendar);
        this.setCurrentCalendar(calendar);
      } else {
        this.$log.debug(
          "Calendar Index: No calendar specified, defaulting to:",
          this.calendarId
        );
        this.getCalendar(this.calendarId);
      }
    } else if (this.calendarId != undefined) {
      this.$log.debug(
        "Calendar Index: Found non public calendar",
        this.calendarId
      );
      this.getCalendar(this.calendarId);
    } else {
      this.$log.debug("Calendar Index: Not loading any calendars");
    }
  },
  methods: {
    ...mapActions({
      doGetCalendar: "calendar/getOne",
      doSaveCalendar: "calendar/save",
      doGetCalendarEntry: "calendarEntry/getOne",
      doSaveRsvp: "calendarEntryRsvp/save"
    }),
    getCalendar(id) {
      this.$log.debug("Calendar Index: Loading calendar [" + id + "]");
      return this.doGetCalendar(id).then(calendar => {
        this.setCurrentCalendar(calendar);
        return Promise.resolve(calendar);
      });
    },
    getCalendarEntry(id) {
      this.$log.debug("Calendar Index: Loading calendar entry", id);
      return this.doGetCalendarEntry(id).then(entry => {
        this.setCurrentCalendarEntry(entry);
        return Promise.resolve(entry);
      });
    },
    handleCreate() {
      // If we're not on the calendar route then navigate to the calendar page
      if (this.$route.name == "calendar.index") {
        this.$log.debug("Chat: Changing mode");
        this.mode =
          this.mode == "creating"
            ? this.calendar || this.groupCalendar
              ? "replying"
              : "creating"
            : "creating";
      } else {
        this.$log.debug(
          "Chat: Navigating to calendar index because we're on",
          this.$route.name
        );
        this.$router.push({
          name: "calendar.index"
        });
      }
    }
  }
};
</script>

<style lang="scss">
@import "../../styles/variables.scss";

.calendar-container-wrapper {
  max-height: calc(100vh - 100px) !important;
  overflow: hidden;
}

.calendar-container > .v-card__text > div {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 10px;
}

.calendar-container .v-tabs__items .v-tabs__content {
  height: 100%;
}

.v-tabs__container--grow .v-tabs__div.add-tab {
  flex: 0 0 64px;
  max-width: 64px;
  height: 48px;
}

.v-tabs__container--grow .v-tabs__div.add-tab .v-tabs__item {
  width: 48px;
  height: 48px;
}

.v-tabs__div.with-chip .v-tabs__item {
  justify-content: space-between;
  margin-left: 10px;
}

.v-tabs__div.with-chip .v-tabs__item span {
  color: $content-title-color;
  font-weight: 900;
  letter-spacing: 1px;
  line-height: 14px;
}

.v-chip.calendar-chip {
  text-align: center;
  max-width: 22px;
  height: 16px;
  border-radius: 3px;
  background: $primary-color !important;
  padding: 4px;
}

.v-chip.calendar-chip .v-chip__content {
  padding: 0;
  color: $white-color;
}
</style>
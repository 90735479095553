<template>
  <VNavigationDrawer
    v-model="drawerOpen"
    class="calendar-calendars wethrive-shadow"
    :fixed="$vuetify.breakpoint.smAndDown"
    :permanent="$vuetify.breakpoint.mdAndUp"
    :temporary="$vuetify.breakpoint.smAndDown"
  >
    <VList
      v-if="hasCalendars"
      two-line
      id="infinite-calendars"
    >
      <VListItem v-if="calendarMode !== 'single'">
        <VListItemAction>
          <VCheckbox
            v-model="allCalendars"
            @change="selectAllCalendars"
          />
        </VListItemAction>
        <VListItemTitle>Calendars</VListItemTitle>
      </VListItem>
      <VListGroup
        v-for="(group, key) in currentCalendars"
        no-action
        :key="key"
        :value="true"
      >
        <VListItem
          slot="activator"
          @click.prevent
        >
          <VListItemTitle style="text-transform: capitalize">
            {{ key }}
          </VListItemTitle>
        </VListItem>
        <template v-if="group && group.length">
          <VListItem
            v-for="(item, index) in group"
            two-line
            :key="index"
            :disabled="item.isDeleting"
            @click.stop="() => {}"
          >
            <VListItemAction>
              <VCheckbox
                v-model="activeCalendars"
                multiple
                :color="item.color"
                :value="item.id"
                :disabled="item.isDeleting"
                @change="$emit('updateActiveCalendars', activeCalendars)"
              />
            </VListItemAction>
            <VListItemContent
              :class="{
                'text--grey': item.isDeleting,
              }"
            >
              <VListItemTitle>{{ item.title }}</VListItemTitle>
              <VListItemSubtitle v-if="item.calendar_type">
                From: {{ item.calendar_type.capitalize() }}
              </VListItemSubtitle>
            </VListItemContent>
            <VListItemAction>
              <VRow row>
                <VCol>
                  <VBtn
                    v-if="$isUserAdmin"
                    icon
                    href="#"
                    text:disabled="item.isDeleting"
                    @click="onSetFormDialogModel(item)"
                  >
                    <VIcon>edit</VIcon>
                  </VBtn>
                </VCol>
                <VCol>
                  <VBtn
                    v-if="$isUserAdmin"
                    icon
                    href="#"
                    text:disabled="item.isDeleting"
                    :loading="item.isLoading"
                    @click="onDeleteItem(item)"
                  >
                    <VIcon>delete</VIcon>
                  </VBtn>
                </VCol>
              </VRow>
            </VListItemAction>
          </VListItem>
          <infinite-loading
            v-if="canGetMore"
            force-use-infinite-wrapper="#infinite-calendars"
            :distance="25"
            :identifier="new Date()"
            @infinite="activity.isLoading && existing instanceof Object ? () => {} : getCalendars"
          />
        </template>
        <template v-else>
          <VListItem>
            <VListItemTitle style="text-transform: capitalize">
              No Calendars added yet
            </VListItemTitle>
          </VListItem>
        </template>
      </VListGroup>
    </VList>
    <VList
      v-else
      two-line
    >
      <VListItem>
        <VListItemContent>
          <VListItemTitle>No Calendars</VListItemTitle>
          <VListItemSubtitle v-if="$isUserAdmin">
            <VBtn
              v-if="$route.name !== 'calendar.index'"
              clas="wethrive"
              large
              @click="handleCreate"
            >
              Add
              One Here
            </VBtn>
            <p v-else>
              Add a new one
            </p>
          </VListItemSubtitle>
        </VListItemContent>
      </VListItem>
    </VList>
    <Confirm ref="confirmDelete" />
    <FormDialog
      :current-model="currentModel"
      :is-dialog-open="dialogs.localForm"
      :title="currentModel ? currentModel.title : ''"
      max-width="640px"
      @toggle:form:dialog="onToggleLocalFormDialog"
      @deleteItem="onDeleteItem"
      model-type="Calendar"
    >
      <LocalForm
        :id="currentModel ? currentModel.id : null"
        ref="localForm"
        mode="dialog"
        :entity="currentModel"
        :entity-type="singularEntityType"
        @savedCalendar="(result) => $emit('savedCalendar', result)"
      />
    </FormDialog>
    <FormDialog
      :current-model="currentModel"
      :is-dialog-open="dialogs.google"
      :title="currentModel ? currentModel.title : ''"
      max-width="640px"
      @toggle:form:dialog="onToggleGoogleFormDialog"
      @deleteItem="onDeleteItem"
      model-type="Calendar"
    >
      <GoogleForm
        :id="currentModel ? currentModel.id : null"
        ref="googleForm"
        mode="dialog"
        :entity="currentModel"
        :entity-type="singularEntityType"
        @savedCalendar="(result) => $emit('savedCalendar', result)"
      />
    </FormDialog>
  </VNavigationDrawer>
  <!-- .b__messanger-dialogs -->
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import LocalForm from "./SaveLocal";
import GoogleForm from "./SaveGoogle";
import SearchMixin from "@/mixins/Search";

export default {
  components: {
    LocalForm,
    GoogleForm,
  },
  mixins: [SearchMixin],
  props: {
    disableDrawer: {
      type: Boolean,
      default: false,
    },
    drawer: {
      type: Boolean,
      default: false,
    },
    currentCalendar: {
      type: Object,
      default: () => {},
    },
    calendarMode: {
      type: String,
      default: "single",
    },
    existing: {
      type: Object || null,
      default: null,
    },
  },
  data() {
    return {
      dialogs: {
        google: false,
        localForm: false,
      },
      activeFilter: "newest",
      calendar: {},
      calendars: [],
      page: 1,
      isLoading: true,
      isFirstLoad: true,
      activeCalendars: [],
      allCalendars: false,
      localDrawer: false,
    };
  },
  computed: {
    ...mapGetters({
      storeCalendars: "calendar/data",
    }),
    hasCalendars() {
      return (
        this.currentCalendars instanceof Object &&
        Object.keys(this.currentCalendars).length >= 1
      );
    },
    currentCalendars() {
      return this.existing instanceof Object &&
        Object.keys(this.existing).length
        ? this.existing
        : this.calendars.data;
    },
    drawerOpen: {
      set(value) {
        this.localDrawer = !this.localDrawer;
        this.$emit("toggleDrawer", this.localDrawer);
      },
      get(value) {
        return this.drawer;
      },
    },
    singularEntityType() {
      return this.currentModel instanceof Object &&
        this.currentModel.entity_type
        ? this.currentModel.entity_type.singularize()
        : "";
    },
    canGetMore() {
      return (
        !this.isFirstLoad &&
        this.hasThreads &&
        this.calendars.current_page < this.calendars.last_page
      );
    },
  },
  watch: {
    currentCalendars: function (value) {
      if (value) {
        this.setInitialActiveCalendars();
      }
    },
  },
  created() {
    this.$log.debug("Calendars: Mounted calendars component...");
    if (this.existing) {
      this.setCalendars(this.existing);
      this.setInitialActiveCalendars();
      this.isLoading = false;
    } else {
      this.getCalendars();
    }
    // this.refresh();
  },
  methods: {
    ...mapActions({
      doGetCalendars: "calendar/getAll",
      doDeleteCalendar: "calendar/delete",
      setCalendars: "calendar/setAll",
    }),
    refresh() {
      if (!this.existing) {
        this.$log.debug("Calendars: Refreshing calendars...");
        this.getCalendars();
      }
    },
    setInitialActiveCalendars() {
      this.activeCalendars = Object.values(this.currentCalendars)
        .map(function (calendars) {
          return calendars instanceof Array
            ? calendars.map((calendar) => calendar.id)
            : [];
        })
        .flat();
      this.allCalendars = true;
    },
    getCalendars() {
      let data = {
        page: this.calendars ? this.calendars.current_page : 1,
      };
      if (
        !this.calendars.total ||
        this.calendars.last_page > data.current_page
      ) {
        data.page = this.calendars.current_page
          ? this.calendars.current_page + 1
          : 1;
        this.$log.debug(
          "Calendars: Getting calendars...{page: " + data.page + "}",
          "Mode",
          this.mode
        );
        this.isLoading = true;
        return this.doGetCalendars(data).then((response) => {
          this.isLoading = this.isFirstLoad = false;
          this.calendars = this.storeCalendars;
          this.setInitialActiveCalendars();
          this.$emit("calendars", this.calendars);
          Promise.resolve(response);
        });
      } else {
        this.$log.debug(
          "Calendars: No more calendars to get...{page: " + data.page + "}"
        );
      }
    },
    onLoadCalendars(calendar) {
      this.$log.debug(
        "Calendars: Loading calendars in ...[" + calendar.title + "]"
      );
      this.$emit("loadCalendars", calendar);
    },
    filterCalendars(index) {
      this.activeFilter = index;
      this.doGetCalendars({
        sort: this.activeFilter,
      }).then((result) => {
        this.calendars = result || this.calendars;
      });
    },
    getLocalCalendar(search) {
      const calendarId = `${this.$user.id}-${search.calendar_id}`;
      const originalCalendarId = search.calendar_id;
      return this.calendars && this.calendars.data
        ? this.calendars.data.find((calendar) => {
            return (
              [calendar.calendar_id, calendar.id].indexOf(calendarId) !== -1 ||
              [calendar.calendar_id, calendar.id].indexOf(
                originalCalendarId
              ) !== -1
            );
          })
        : null;
    },
    handleCreate() {
      this.$emit("startCreate");
    },
    addCalendar(calendar) {
      this.$log.debug("Calendars: Adding new calendar", calendar);
      this.calendars.data.push(calendar);
      this.activeCalendars.push(calendar.id);
    },
    onSetFormDialogModel(item) {
      this.currentModel = item;
      switch (item.calendar_type) {
        case "google":
          this.onToggleGoogleFormDialog();
          break;

        default:
          this.onToggleLocalFormDialog();
          break;
      }
    },
    onDelete(calendar) {
      return this.doDeleteCalendar(calendar).then(() => {
        this.$emit("calendarDeleted", calendar);
        const index = this.activeCalendars.findIndex(
          (id) => id === calendar.id
        );
        if (index > -1) {
          this.activeCalendars.splice(index, 1);
        }
      });
    },
    onToggleLocalFormDialog() {
      this.dialogs.localForm = !this.dialogs.localForm;
      this.$refs["saveForm"] = this.$refs.localForm;
    },
    onToggleGoogleFormDialog() {
      this.dialogs.google = !this.dialogs.google;
      this.$refs["saveForm"] = this.$refs.googleForm;
    },
    selectAllCalendars() {
      if (this.allCalendars) {
        this.activeCalendars = Object.values(this.calendars.data)
          .map(function (calendars) {
            return calendars.map((calendar) => calendar.id);
          })
          .flat();
      } else {
        this.activeCalendars = [0];
      }
      this.$emit("updateActiveCalendars", this.activeCalendars);
    },
  },
};
</script>

<style lang="scss">
@import "../../styles/variables.scss";

.calendar-calendars.v-card {
  height: 100%;
}

.calendar-items-nav.v-toolbar--card,
.calendar-items-nav.v-toolbar--card .v-toolbar__content {
  height: 48px !important;
  padding: 0 0;
}

.calendar-items-nav.v-toolbar--card .v-toolbar__content .v-toolbar__items {
  align-items: center;
  justify-content: space-around;
  padding: 15px;
  width: 100%;
}

.calendar-items-nav.v-toolbar--card .v-toolbar__content {
  width: 100%;
}

.calendar-items-nav.v-toolbar--card .v-btn,
.calendar-items-nav
  .v-toolbar__items
  .v-btn:not(.v-btn--floating):not(.v-btn--icon),
.calendar-items-nav .v-toolbar__items .v-menu,
.v-toolbar__items .v-menu__activator {
  border-radius: 2px;
  height: 32px;
  text-transform: none;
  color: $font-light-color;
  flex: 1 1 auto;
}

.calendar-items-nav.v-toolbar--card .v-btn.active {
  background-color: $white-color !important;
  box-shadow: 0 0 10px 0 $box-shadow-color !important;
  color: #000;
}

.calendar-calendars .calendar-calendars-list {
  height: 100%;
}

.calendar-calendars .v-list__group__items--no-action .v-list__tile {
  padding-left: 22px;
}
</style>